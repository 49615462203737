// import { useApiStore } from '../stores/apiStore';
import { useConfig } from './useConfig';

export const useAPI = () => {
  const config = useConfig();

  const callAPI = async (method, url, data = null) => {
    try {
      const fullUrl = config.API_URL + url;
      const response = await fetch(fullUrl, {
        method,
        body: data ? JSON.stringify(data) : null,
        headers: config.headers,
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
      }

      return await response.json();
    } catch (error) {
      console.error('API 请求出错:', error);
      throw error;
    }
  };

  return {
    // 开始：身份验证相关的 API 方法
    signIn: data => callAPI('POST', '/login', data),
    signUp: data => callAPI('POST', '/createUser', data),
    veriyEmail: data => callAPI('POST', '/verifyEmail', data),
    forgotPassword: data => callAPI('POST', '/forgot-password', data),
    resetPassword: data => callAPI('POST', '/reset-password', data),
    logout: () => callAPI('POST', '/logout'),
    // 结束：身份验证

    // 设备相关
    getDevices: () => callAPI('GET', '/devices'),
    deleteDevice: id => callAPI('GET', `/devices?id=${id}`),

    // 订单历史
    userPackages: query => callAPI('GET', '/user-type-packages?user_type=' + query),
    orderHistory: () => callAPI('GET', '/order-history'),

    // FAQs
    getFaqs: () => callAPI('GET', '/faqs'),
    getdefaultData: query => callAPI('GET', '/defaultData?type=' + query),

    // 过期日期
    getExpires: query =>
      callAPI('GET', `/socket/remaining-free-trail-for-socket?user_id=${query.userId}&deviceId=${query.deviceId}`),



    // USDT 支付
    paymentUSDT: data => callAPI('POST', '/paymentUSDT', data),

    // Airspay 支付请求方法
    airspayPaymentRequest: data => callAPI('POST', '/payment-airspay', data),

    // 支付成功回调 [用户]
    userPaymentSuccess: data => callAPI('POST', '/user-subscription-success', data),
    userTypePaymentSuccess: data => callAPI('POST', '/user-type-package-subscription-success', data),

    updateUserType: data => callAPI('POST', '/update-user-type', data),

    usdtPaymentSuccess: data => callAPI('POST', 'payment-success-usdt', data),
  };
};
